import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FirstCardBeforeCircleImg from '../../../assets/DesignIcons/circle-pattern-1.png';

export const WhyChooseUsSection = styled.section`
    padding:100px 0px 100px;
    position:relative;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -80px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        background-position-x: -80px;
        left: 0px;
        background-repeat: no-repeat;
        background-image: url(${FirstCardBeforeCircleImg});
    }
    @media ${device.tablet} {
        padding:80px 5px 80px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:40px;
    }
`;

export const Heading = styled.h2`
    text-align:left;
`;

export const Description = styled.p`
    margin-bottom:40px;
`;

export const WhyChooseContentList = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    img{
        width: 65px;
        margin-right: 20px;
        flex-shrink: 0;
    }

    :last-child{
        margin-bottom: 0px;
    }
    
    @media ${device.mobileXL} {
        img{
            width:50px;
            margin-right:15px;
        }
    }
`;

export const WhyChooseContenttextLayout = styled.div`
    h5{
        line-height:1;
        margin-bottom:5px;
    }
    p{
        margin-bottom:0;
    }
`;


export const ImageHolder = styled.div`
    img{
        width: 100%;
        height: 500px;
        border-radius:4px;
    }

`;
