import styled from 'styled-components';
import {Commonbtn,SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import SectionCircleImg from '../../../assets/DesignIcons/circle-pattern-3.png';

export const PricingSection = styled.div`
    padding:100px 0px;
    background:#f9fafc;
    position:relative;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -180px;
        background-size: 100%;
        width: 250px;
        height: 250px;
        right: 0px;
        background-position-x: 120px;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }
    :after{
        content: "";
        display: block;
        position: absolute;
        bottom: -50px;
        background-size: 100%;
        width: 250px;
        height: 250px;
        left: 0px;
        background-position-x: -120px;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }
    @media ${device.laptop} {
        padding-bottom:70px;
    }
    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const PricingHeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const PricingHeading = styled(SectionHeading)`
    text-align: center;
`;

export const PricingPlanCol = styled.div`
    background:#fff;
    border-radius: 4px;
    text-align:center;
    padding:70px 25px;
    border-top:3px solid #ff3c1c;
    box-shadow: 0px 0px 10px 0px #d8d8d8;
    margin:40px 0px 0px;
    min-height:250px;
    h4{
        margin-bottom:30px;
    }
    img{
        height:60px;
        margin-bottom:30px;
    }
    h1{
        margin-bottom:0px;
    }
    hr{
        max-width: 100px;
        margin:auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    @media ${device.laptop} {
        margin-top:0px;
        margin-bottom:30px;
    }

`;
export const LowOpacity = styled.li`
    opacity:.7;
`;
export const PricingPlanColCenter = styled.div`
    border-radius: 4px;
    text-align:center;
    padding:60px 25px;
    border-top:3px solid #ff3c1c;
    box-shadow: 0px 0px 10px 0px #d8d8d8;
    padding-top:0px;
    padding-bottom:70px;
    background:#fff;
    h4{
        text-align: center;
        margin-bottom:35px;
        padding-top:70px;
    }
    ul{
        padding:0px;
        margin-left:0px;
    }
    img{
        height: 60px;
        margin-bottom: 30px;
    }
    hr{
        max-width: 100px;
        margin:auto;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    @media ${device.laptop} {
        margin-top:0px;
        margin-bottom:30px;
    }

`;

export const PricingRecomended = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    background: #ff3c1c;
    width: 120px;
    padding: 3px 20px;
    margin: auto;
    border-radius: 4px;
    height: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    p{
        color: #fff;
        margin: 0px;
        font-size: 12px;
        line-height: 12px;
    }
`;



export const PricingPlanCenterText = styled.div`
    font-weight:500;
    margin-bottom:5px;

    ul{
        padding:0px;
        margin-bottom:30px;
    }
    li{
        list-style:none;
        font-size:14px;
        cursor:pointer;
    }
`;



export const PricingSpan = styled.span`
    font-size: 18px;
    color: #282828;
    line-height: 28px;
    text-align: center;
    display:block;
`;
export const PricingCostSpan = styled.span`
    font-size: 30px;
    margin-right: 5px;
`;

export const PricingPlanLightText = styled.div`
    ul{
        padding:0px;
        margin-bottom:30px;
    }
    li{
        list-style:none;
        font-size:14px;
        cursor:pointer;
    }
`;

export const PricingPlanBtn = styled(Commonbtn)`
    background:none;
    color:#ff3c1c;
    :hover{
        background:#ff3c1c;
        color:#fff;
    }
`;

export const PricingPlanBtnCenter = styled(Commonbtn)`

`;
