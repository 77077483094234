import styled from 'styled-components';
import {Commonbtn} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';

export const BannerSection = styled.section`
    // min-height:100vh;
`;

export const BannerWrapper = styled.div`

    .slick-list{
        line-height:0;
    }

    .slick-active{
        z-index:999;
    }

    .slick-dots{
        height:100%;
        display:flex;
        align-items:center;
        bottom:0;
        width:auto;
        position:absolute;
        right:0;
        margin:0px;
        padding-right:15px;

        @media (min-width:1440px) {
            margin-right: calc(50% - 570px);
        }
        @media ${device.laptopL} {
            margin-right: calc(50% - 570px);
        }

        @media ${device.laptopM} {
            margin-right: calc(50% - 480px);
        }

        @media ${device.laptop} {
            margin-right: calc(50% - 360px);
        }

        @media ${device.tablet} {
            height:auto;
            display:block;
            left:0;
            right:0;
            bottom:30px;
            margin-left:0;
            padding-left:0px;
            margin:auto;
        }

        @media ${device.mobileL} {
            padding:0px;
            bottom:30px;
        }
    }

    ul{
        margin:0px;
    }

    li{
        display:block !important;
        margin:2px 0px;

        @media ${device.tablet} {
            display:inline-block !important;
            margin:5px 5px;
            height:auto;
            width:auto;
        }
    }
    li div{
        padding:3px 0px;
    }

    .slick-dots span{
        width:20px;
        height:3px;
        border-radius:2px;
        display:block;
        background:#fff;
    }

    .slick-active span{
        width:35px !important;
        height:3px !important;
        background:#ff3c1c;
    }
`;

export const BannerSlide = styled.div`
    position:relative;
`;

export const BannerImageHolder = styled.div`
    position:relative;
    min-height:100vh;
    margin-left: auto;

    .bannerImage{
        min-height:440px;
        height:100vh;
    }

`;

export const BannerContents = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:100%;
    display:flex;
    align-items:center;
    padding:50px 0px;
`;

export const BannerLayout = styled.div`
    max-width:55%;
    /* margin-left:auto; */

    @media ${device.laptopM} {
        max-width:65%;
    }

    @media ${device.tablet} {
        max-width:100%;
        padding: 0px 10px 0px;
    }

    h1{
        color:#fff;

        @media ${device.tablet} {
            text-align:center;
        }
    }
    p{
        color:#fff;
        font-size:16px;
        line-height:24px;
        margin-bottom: 30px;

        @media ${device.tablet} {
            text-align:center;
            margin-left:0px;
            font-size:15px;
            line-height:23px;
        }
    }
`;

export const ButtonLayout = styled.div`
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        justify-content:center;
        margin-bottom:30px;
    }
    @media ${device.mobileL} {
        flex-direction:column;
    }
`;

export const LearnMoreBtn = styled(Commonbtn)`
    line-height: 1.45;

    @media ${device.mobileXL} {
        padding: 8px 40px;
    }
`;

export const GetInTouchBtn = styled(Commonbtn)`
    margin-left: 20px;
    color: #fff;
    border: 2px solid #fff;
    padding: 10px 40px;
    text-decoration: none;
    display: inline-block;
    line-height: 1.45;
    background:none;
    :hover{
        background: #fff;
        border-color: #ff3c1c;
        color: #ff3c1c;
    }

    @media ${device.mobileL} {
        margin-left:0px;
        margin-top:15px;
        padding: 8px 40px;
    }

`;
