import React from 'react';
import {FeaturedServicesSection,FeaturedServicesLayout,BeforeCircleFirstCard,BeforeCircleSecondCard,
BeforeCircleLastCard,FeaturedServicesCardImg,FeaturedServicesRow,ArrowIconWrapper,
} from './featuredservices.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';

const FeaturedServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            homePage1Json {
                FeaturedServices {
                    FeaturedServicesLayout{
                        FeaturedServicesCardIcon
                        FeaturedServicesCardHeading
                        FeaturedServicesCardPara
                        FeaturedServicesCardArrowIcon
                    }
                }
            }
        }
    `);
    const FeaturedServicesData = JSONData.homePage1Json.FeaturedServices;
    return(
        <FeaturedServicesSection id="FeaturedServicesSection">
            <Container>
                <FeaturedServicesRow>
                    <Col md="12" lg="12">
                        <FeaturedServicesLayout>
                            <Row>
                                <Col lg="4" md="4" sm="12">
                                    <BeforeCircleFirstCard>
                                        <FeaturedServicesCardImg>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[0].FeaturedServicesCardIcon} alt=""/>
                                        </FeaturedServicesCardImg>
                                        <h5>{FeaturedServicesData.FeaturedServicesLayout[0].FeaturedServicesCardHeading}</h5>
                                        <p>{FeaturedServicesData.FeaturedServicesLayout[0].FeaturedServicesCardPara}</p>
                                        <ArrowIconWrapper>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[0].FeaturedServicesCardArrowIcon} alt=""/>
                                        </ArrowIconWrapper>
                                    </BeforeCircleFirstCard>
                                </Col>
                                <Col lg="4" md="4" sm="12">
                                    <BeforeCircleSecondCard>
                                        <FeaturedServicesCardImg>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[1].FeaturedServicesCardIcon} alt=""/>
                                        </FeaturedServicesCardImg>
                                        <h5>{FeaturedServicesData.FeaturedServicesLayout[1].FeaturedServicesCardHeading}</h5>
                                        <p>{FeaturedServicesData.FeaturedServicesLayout[1].FeaturedServicesCardPara}</p>
                                        <ArrowIconWrapper>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[1].FeaturedServicesCardArrowIcon} alt=""/>
                                        </ArrowIconWrapper>
                                    </BeforeCircleSecondCard>
                                </Col>
                                <Col lg="4" md="4" sm="12">
                                    <BeforeCircleLastCard>
                                        <FeaturedServicesCardImg>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[2].FeaturedServicesCardIcon} alt=""/>
                                        </FeaturedServicesCardImg>
                                        <h5>{FeaturedServicesData.FeaturedServicesLayout[2].FeaturedServicesCardHeading}</h5>
                                        <p>{FeaturedServicesData.FeaturedServicesLayout[2].FeaturedServicesCardPara}</p>
                                        <ArrowIconWrapper>
                                            <img src={FeaturedServicesData.FeaturedServicesLayout[2].FeaturedServicesCardArrowIcon} alt=""/>
                                        </ArrowIconWrapper>
                                    </BeforeCircleLastCard>
                                </Col>
                            </Row>
                        </FeaturedServicesLayout>
                    </Col>
                </FeaturedServicesRow>
            </Container>
        </FeaturedServicesSection>
)
}
export default FeaturedServices;
