import React from 'react';
import {AboutSection,CustomRow,ImageHolderWrapper,ImageHolderTopLayout,ImageHolder,ImageHolderBottom,
    TextLayout,Heading,FeaturesLayout,FeaturesTextLayout,QuoteBtn
} from './about.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                homePage1Json{
                    About{
                       Heading
                       Description1
                       Description2
                       Img1{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       Img2{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       Img3{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                       }
                       FeaturesImg1
                       FeaturesHeading1
                       FeaturesImg2
                       FeaturesHeading2
                       QuoteBtn
                    }
                }
            }
    `);
    const AboutData = JSONData.homePage1Json.About;
    return (
        <AboutSection id="aboutSection">
            <Container>
                <CustomRow>
                    <Col lg={6}>
                        <ImageHolderWrapper>
                        <ImageHolderTopLayout>
                                <ImageHolder plr={"0px 5px 0px 0px"}>
                                    <GatsImg
                                        fluid={AboutData.Img1.childImageSharp.fluid}
                                        alt=""
                                    />
                                </ImageHolder>
                                <ImageHolder plr={"0px 0px 0px 5px"}>
                                    <GatsImg
                                        fluid={AboutData.Img2.childImageSharp.fluid}
                                        alt=""
                                    />
                                </ImageHolder>
                            </ImageHolderTopLayout>
                            <ImageHolderBottom>
                                <GatsImg
                                    fluid={AboutData.Img3.childImageSharp.fluid}
                                    alt=""
                                />
                            </ImageHolderBottom>
                        </ImageHolderWrapper>
                    </Col>
                    <Col lg={6}>
                        <TextLayout>
                            <Heading>
                                {AboutData.Heading}
                            </Heading>
                            <p>
                            {AboutData.Description1}
                            </p>
                            <p>
                            {AboutData.Description2}
                            </p>

                            <Row>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <img
                                            src={AboutData.FeaturesImg1}
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {AboutData.FeaturesHeading1}
                                            </h5>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                                <Col md={6} xs={6}>
                                    <FeaturesLayout>
                                        <img
                                            src={AboutData.FeaturesImg2}
                                            alt=""
                                        />
                                        <FeaturesTextLayout>
                                            <h5>
                                            {AboutData.FeaturesHeading2}
                                            </h5>
                                        </FeaturesTextLayout>
                                    </FeaturesLayout>
                                </Col>
                                <Col md={6} xs={12}>
                                    <QuoteBtn href="/">{AboutData.QuoteBtn}</QuoteBtn>
                                </Col>
                            </Row>
                        </TextLayout>
                    </Col>
                </CustomRow>
            </Container>
        </AboutSection>
    );
}

export default About;
