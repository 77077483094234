import styled from 'styled-components';
import {SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SectionRingImg from '../../../assets/DesignIcons/circle-pattern-3.png';
import SectionCircleImg from '../../../assets/DesignIcons/circle-pattern-1.png';

export const OurServicesSection = styled.section`
    position:relative;
    background:#fafafa;
`;

export const BackgroundImage = styled.img`
    height:295px;
    width:100%;
    position:relative;
    z-index:9;
    object-fit:cover;

    @media ${device.tablet}{
        height:260px;
    }
`;

export const SectionWrapper = styled.div`
    padding:0px 0px 70px;
    position:relative;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: 220px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: 0px;
        background-repeat: no-repeat;
        background-image: url(${SectionRingImg});
    }
    :after{
        content: "";
        display: block;
        position: absolute;
        bottom: 40px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        right: 20px;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }

    @media ${device.tablet} {
        padding:0px 5px 50px;
    }
`;

export const HeadingLayout = styled.div`
    position:absolute;
    top: 100px;
    z-index: 99;
    left: 0;
    right: 0;

    @media ${device.tablet} {
        top: 80px;
    }

`;

export const Heading = styled(SectionHeading)`
    color:#fff;
    margin-bottom:75px;
    padding:0px 25px;
    text-align: center;
    text-transform:uppercase;

    @media ${device.tablet}{
        margin-bottom:60px;
    }
`;

export const OurServicesRow = styled(Row)`
    margin-left:-35px; //15px margin in Col, 20px margin in OurServicesCardWrapper
    margin-right:-35px;
    margin-top:-80px;

    @media ${device.laptop} {
        margin-left:-15px;
        margin-right:-15px;
    }
`;

export const OurServicesCardWrapper = styled.div`
    background:#fff;
    cursor:pointer;
    /* margin:0px 20px 30px; */
    margin: 0px 0px 30px;
    box-shadow: 0px 0px 15px 0px #ddd;
    border-radius:4px;
    min-height:300px;

    @media ${device.laptop} {
        margin:0px 0px 30px;
    }
`;

export const OurServicesCard = styled.div`
    background:#fff;
    cursor:pointer;
    padding: 50px 30px;
    position:relative;
    z-index:10;
    border-radius:4px;
    min-height: 300px;
    h5{
        // text-transform:uppercase;
        text-align:center;
    }
    p{
        margin-bottom:0px;
        text-align:center;
    }
`;

export const OurServicesCardImgHolder = styled.div`
    margin-bottom:20px;
    text-align:center;
    img{
        margin-bottom:0px;
        height: 60px;
    }
`;
