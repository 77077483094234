import styled from 'styled-components';
import {SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device'
import 'bootstrap/dist/css/bootstrap.min.css';

export const BlogSection = styled.section`
    padding:100px 0px;

    @media ${device.laptop} {
        padding:100px 0px 70px;
    }

    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;


export const HeadingLayout = styled.div`
    margin-bottom:75px;
    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:10px;
    text-align: center;
`;

export const BlogOuterWrapper = styled.div`

`;

export const BlogCard = styled.a`
    display: flex;
    background: #fff;
    box-shadow: 0px 0px 8px 1px #ddd;
    border-radius: 4px;
    overflow: hidden;
    border-right: 2px solid #ff3c1c;
    :hover{
        text-decoration:none;
    }

    @media ${device.laptop} {
        margin-bottom:30px;
    }
    @media ${device.mobileXL} {
        flex-direction:column;
    }
`;

export const BlogCardImgWrapper = styled.figure`
    margin: 0px;
    width: 230px;
    flex-shrink: 0;
    overflow: hidden;

    img{
        object-fit: cover;
        min-height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
        -webkit-transition: transform .3s ease-in-out !important;
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
    :hover img {
        -webkit-transform: scale(1.05);
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
    }
    @media ${device.mobileXL} {
        width:100%;
        img{
            width:100%;
            height:300px;
        }
    }

`;
export const BlogCardTxtWrapper = styled.div`
    padding:20px;
    h5{
        font-size: 22px;
        color: #282828;
        line-height: 28px;
        text-align: left;
        margin-bottom: 20px;
        font-weight: 400;
    }
    p{
        color: #545454;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
    }
    h6{
        text-align: right;
        margin:0px;
    }
`;
