import React from 'react';
import {PricingSection,PricingHeadingWrapper,
    PricingHeading,PricingPlanCol,PricingSpan,PricingCostSpan,
    PricingRecomended,PricingPlanBtn,PricingPlanLightText,PricingPlanColCenter,LowOpacity,
    PricingPlanCenterText,PricingPlanBtnCenter
} from './pricing.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Pricing = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            homePage1Json {
                Pricing {
                    PricingHeading
                    PricingPlanCol{
                        PricingPlanHeading
                        PricingPlanImg
                        PricingPlanPrice
                        PricingPlanPriceSpan
                        PricingPlanLightText1
                        PricingPlanLightText2
                        PricingPlanLightText3
                        PricingPlanLightText4
                        PricingPlanLightText5
                        PricingPlanBtn
                    }
                }
            }
        }
    `);
    const PricingData = JSONData.homePage1Json.Pricing;
    return (
        <PricingSection id="pricingContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PricingHeadingWrapper>
                            <PricingHeading>
                                {PricingData.PricingHeading}
                            </PricingHeading>
                        </PricingHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                    <Col lg="4" md="12" sm="12">
                        <PricingPlanCol>
                            <h4>{PricingData.PricingPlanCol[0].PricingPlanHeading}</h4>
                            <img src={PricingData.PricingPlanCol[0].PricingPlanImg} alt=""/>
                            <h1><PricingCostSpan>$</PricingCostSpan>{PricingData.PricingPlanCol[0].PricingPlanPrice} <PricingSpan>{PricingData.PricingPlanCol[0].PricingPlanPriceSpan}</PricingSpan></h1>
                            <hr/>
                            <PricingPlanLightText>
                            <ul>
                                <li>{PricingData.PricingPlanCol[0].PricingPlanLightText1}</li>
                                <LowOpacity>{PricingData.PricingPlanCol[0].PricingPlanLightText2}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[0].PricingPlanLightText3}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[0].PricingPlanLightText4}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[0].PricingPlanLightText5}</LowOpacity>
                            </ul>
                            </PricingPlanLightText>
                            <PricingPlanBtn href="/">{PricingData.PricingPlanCol[0].PricingPlanBtn}</PricingPlanBtn>
                        </PricingPlanCol>
                    </Col>
                    <Col lg="4" md="12" sm="12">
                        <PricingPlanColCenter>
                            <PricingRecomended>
                                <p>Recomended</p>
                            </PricingRecomended>
                            <h4>{PricingData.PricingPlanCol[1].PricingPlanHeading}</h4>
                            <img src={PricingData.PricingPlanCol[1].PricingPlanImg} alt=""/>
                            <h1><PricingCostSpan>$</PricingCostSpan>{PricingData.PricingPlanCol[1].PricingPlanPrice} <PricingSpan>{PricingData.PricingPlanCol[1].PricingPlanPriceSpan}</PricingSpan></h1>
                            <hr/>
                            <PricingPlanCenterText>
                            <ul>
                                <li>{PricingData.PricingPlanCol[1].PricingPlanLightText1}</li>
                                <li>{PricingData.PricingPlanCol[1].PricingPlanLightText2}</li>
                                <li>{PricingData.PricingPlanCol[1].PricingPlanLightText3}</li>
                                <LowOpacity>{PricingData.PricingPlanCol[1].PricingPlanLightText4}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[1].PricingPlanLightText5}</LowOpacity>
                            </ul>
                            </PricingPlanCenterText>
                            <PricingPlanBtnCenter href="/">{PricingData.PricingPlanCol[1].PricingPlanBtn}</PricingPlanBtnCenter>
                        </PricingPlanColCenter>
                    </Col>
                    <Col lg="4" md="12" sm="12">
                        <PricingPlanCol>
                            <h4>{PricingData.PricingPlanCol[2].PricingPlanHeading}</h4>
                            <img src={PricingData.PricingPlanCol[2].PricingPlanImg} alt=""/>
                            <h1><PricingCostSpan>$</PricingCostSpan>{PricingData.PricingPlanCol[2].PricingPlanPrice} <PricingSpan>{PricingData.PricingPlanCol[2].PricingPlanPriceSpan}</PricingSpan></h1>
                            <hr/>
                            <PricingPlanLightText>
                            <ul>
                                <LowOpacity>{PricingData.PricingPlanCol[2].PricingPlanLightText1}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[2].PricingPlanLightText2}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[2].PricingPlanLightText3}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[2].PricingPlanLightText4}</LowOpacity>
                                <LowOpacity>{PricingData.PricingPlanCol[2].PricingPlanLightText5}</LowOpacity>
                            </ul>
                            </PricingPlanLightText>
                            <PricingPlanBtn href="/">{PricingData.PricingPlanCol[2].PricingPlanBtn}</PricingPlanBtn>
                        </PricingPlanCol>
                    </Col>
                </Row>
            </Container>
        </PricingSection>
    );
}

export default Pricing;
