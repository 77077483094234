import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import FirstCardBeforeCircleImg from '../../../assets/DesignIcons/circle-pattern-2.png';
import SectionRingImg from '../../../assets/DesignIcons/circle-pattern-3.png';

import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const FeaturedServicesSection = styled.section`
    padding:100px 0px 70px;
    background:#f9fafc;
    position:relative;
    ::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -80px;
        background-size: 100%;
        width: 200px;
        height: 200px;
        right: 0px;
        background-position-x: 120px;
        background-repeat: no-repeat;
        overflow: inherit !important;
        background-image: url(${SectionRingImg});
    }
    @media ${device.tablet} {
        padding:80px 5px 50px;
    }
`;

export const FeaturedServicesRow = styled(Row)`
    align-items:center;
`;

export const FeaturedServicesLayout = styled.div`
`;

export const FeaturedServicesCard = styled.div`

    background:#fff;
    cursor:pointer;
    border-radius:4px;
    min-height: 250px;
    padding: 25px;
    box-shadow: 0px 0px 8px 1px #ddd;
    transition:all .5s;
    margin:0px 0px 30px;
    overflow:hidden;
    background-size: 180px auto;
    background-repeat: no-repeat;
    background-position: right bottom;

    img{
      height: 50px;
      margin-bottom: 20px;
    }

    @media ${device.tablet} {
    //    margin:10px 0px;
    }
`;
export const ArrowIconWrapper = styled.div`
    transition: all .5s;
    ${FeaturedServicesCard}:hover & {
        padding-left:10px;
        transition: all .5s;
    }
    img{
        height: 15px;
        margin-bottom: 0px;
    }
`;
export const BeforeCircleFirstCard = styled(FeaturedServicesCard)`
    overflow: inherit !important;
    :before{
      content: "";
      display: block;
      position: absolute;
      top: -60px;
      background-size: 100%;
      width: 150px;
      height: 150px;
      left: -60px;
      background-repeat: no-repeat;
      background-image: url(${FirstCardBeforeCircleImg});
    }
`;

export const BeforeCircleSecondCard = styled(FeaturedServicesCard)`
    transform: scale(1.06);
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 1px;
    background: #ff3c1c !important;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -60px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: -60px;
        background-repeat: no-repeat;
        background-image: url(${FirstCardBeforeCircleImg});
    }
    h5{
        color:#fff;
    }
    p{
        color:#fff;
    }

    @media ${device.tablet} {
        transform:none;
        margin-right:0;
        margin-left:0;
    }
`;

export const BeforeCircleLastCard = styled(FeaturedServicesCard)`
    position:relative;
    :before{
      content: "";
      display: block;
      position: absolute;
      top: -60px;
      background-size: 100%;
      width: 150px;
      height: 150px;
      left: -60px;
      background-repeat: no-repeat;
      background-image: url(${FirstCardBeforeCircleImg});
    }
`;

export const FeaturedServicesCardImg = styled.div`
`;
