import React,{Component} from "react";
import {BannerSection,
    BannerWrapper,
    BannerSlide,
    BannerImageHolder,
    BannerContents,
    BannerLayout,
    ButtonLayout,
    LearnMoreBtn,
    GetInTouchBtn,
} from './banner.style'
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Banner extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            fade:true,
            dots:true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,

            appendDots: dots => (
                <div>
                    <ul>
                        {dots}
                    </ul>
                </div>
            ),

            customPaging: i => (
                <div>
                    <span>
                    </span>
                </div>
            )
        };
        return (
            <BannerSection id="homeSection">
                <BannerWrapper>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                        this.props.BannerData.BannerSlides.map((item)=>{
                            return <BannerSlide>
                                <BannerImageHolder>
                                    <GatsImg
                                        fluid={item.BannerImage.childImageSharp.fluid}
                                        alt=""
                                        className="bannerImage"
                                    />
                                </BannerImageHolder>

                                <BannerContents>
                                    <Container>
                                        <BannerLayout>
                                            <h1>{item.BannerHeading}</h1>
                                            <p>{item.BannerSubHeading}</p>
                                        </BannerLayout>

                                        <ButtonLayout>
                                            <LearnMoreBtn href="/">{item.LearnMoreBtn}</LearnMoreBtn>
                                            <GetInTouchBtn href="/">{item.GetInTouchBtn}</GetInTouchBtn>
                                        </ButtonLayout>

                                    </Container>
                                </BannerContents>
                            </BannerSlide>
                        })
                    }
                    </Slider>
                </BannerWrapper>
            </BannerSection>
        );
    }
}

const BannersSection = () => (
    <StaticQuery
        query={graphql`
            query {
                homePage1Json{
                    Banner{
                        BannerSlides{
                            BannerImage{
                                childImageSharp{
                                    fluid(quality: 100){
                                    ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                            BannerHeading
                            BannerSubHeading
                            LearnMoreBtn
                            GetInTouchBtn
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Banner BannerData={data.homePage1Json.Banner}/>
        )}
    />
    )
export default BannersSection;