import React from 'react';
import {WhyChooseUsSection,CustomRow,TextLayout,Heading,Description,
    WhyChooseContentList,WhyChooseContenttextLayout,ImageHolder
} from './whychooseus.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const WhyChooseUs = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                homePage1Json{
                    WhyChooseUs{
                       Heading
                       Description1
                       ListHeading1
                       ListText1
                       ListHeading2
                       ListText2
                       ListHeading3
                       ListText3
                       ListImage1
                       ListImage2
                       ListImage3
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
    const WhyChooseUsData = JSONData.homePage1Json.WhyChooseUs;
        return (
        <WhyChooseUsSection id="WhyChooseUsContainer">
            <Container>
                <CustomRow>
                    <Col md={12} lg={7}>
                        <TextLayout>
                            <Heading>
                            {WhyChooseUsData.Heading}
                            </Heading>
                            <Description>
                            {WhyChooseUsData.Description1}
                            </Description>
                            <WhyChooseContentList>
                                <img alt="" src={WhyChooseUsData.ListImage1}/>
                                <WhyChooseContenttextLayout>
                                    <h5>{WhyChooseUsData.ListHeading1}</h5>
                                    <p>{WhyChooseUsData.ListText1}</p>
                                </WhyChooseContenttextLayout>
                            </WhyChooseContentList>

                            <WhyChooseContentList>
                                <img alt="" src={WhyChooseUsData.ListImage2} />
                                <WhyChooseContenttextLayout>
                                    <h5>{WhyChooseUsData.ListHeading2}</h5>
                                    <p>{WhyChooseUsData.ListText2}</p>
                                </WhyChooseContenttextLayout>
                            </WhyChooseContentList>

                            <WhyChooseContentList>
                                <img alt="" src={WhyChooseUsData.ListImage3} />
                                <WhyChooseContenttextLayout>
                                    <h5>{WhyChooseUsData.ListHeading3}</h5>
                                    <p>{WhyChooseUsData.ListText3}</p>
                                </WhyChooseContenttextLayout>
                            </WhyChooseContentList>

                        </TextLayout>
                    </Col>
                    <Col md={12} lg={5}>
                        <ImageHolder>
                            <GatsImg
                                fluid={WhyChooseUsData.Img.childImageSharp.fluid}
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                </CustomRow>
            </Container>
        </WhyChooseUsSection>
    );
}

export default WhyChooseUs;
