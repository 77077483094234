import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BlogSection,HeadingLayout,Heading,BlogOuterWrapper,BlogCard,BlogCardImgWrapper,BlogCardTxtWrapper
} from './blog.style';

const Blog = () => {
    return(
        <BlogSection id="BlogPage">
            <Container>
                <HeadingLayout>
                    <Heading>
                    Latest Blogs
                    </Heading>
                </HeadingLayout>

                <BlogOuterWrapper>
                    <Row>
                        <Col lg="6" md="12">
                            <BlogCard href="/">
                                <BlogCardImgWrapper>
                                    <img alt="" src="/home-1-images/blog-1.jpg" />
                                </BlogCardImgWrapper>
                                <BlogCardTxtWrapper>
                                    <h5>Lorem Ipsum is simply dummy text of the printing</h5>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                    <h6>23 April 2020</h6>
                                </BlogCardTxtWrapper>
                            </BlogCard>
                        </Col>
                        <Col lg="6" md="12">
                            <BlogCard href="/">
                                <BlogCardImgWrapper>
                                    <img alt="" src="/home-1-images/blog-2.jpg" />
                                </BlogCardImgWrapper>
                                <BlogCardTxtWrapper>
                                    <h5>Lorem Ipsum is simply dummy text of the printing</h5>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour,</p>
                                    <h6>23 April 2020</h6>
                                </BlogCardTxtWrapper>
                            </BlogCard>
                        </Col>
                    </Row>
                </BlogOuterWrapper>
            </Container>
        </BlogSection>
    )
}
export default Blog;
