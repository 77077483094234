import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading,Commonbtn} from '../../../components/Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SectionCircleImg from '../../../assets/DesignIcons/circle-pattern-1.png';
import SectionRingImg from '../../../assets/DesignIcons/circle-pattern-3.png';

export const AboutSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 5px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;

    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const ImageHolderWrapper = styled.div`
    position:relative;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -60px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: -60px;
        z-index: -1;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }
    :after{
        content: "";
        display: block;
        position: absolute;
        bottom: -50px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        right: -20px;
        z-index: -1;
        background-repeat: no-repeat;
        background-image: url(${SectionRingImg});
    }
`;

export const ImageHolderTopLayout = styled.div`
    display:flex;
    align-items:center;
    @media ${device.mobileXL} {
        flex-direction:column;
    }
`;


export const ImageHolder = styled.div`
    height:220px;
    width:50%;
    padding:${props=>props.plr};

    img{
        border-radius:4px;
        height:220px !important;
    }

    @media ${device.laptop} {

        margin-bottom:0px;
    }
    @media ${device.mobileXL} {
        width:100%;
        padding:0px;
        margin-bottom:10px;
    }
`;

export const ImageHolderBottom = styled.div`
    /* height:220px; */
    width:100%;
    padding-top:10px;

    img{
        height:220px;
        border-radius:4px;
        object-fit:cover;
    }

    @media ${device.laptop} {
        padding-right:0px;
        margin-bottom:40px;
    }
    @media ${device.mobileXL} {
        margin-top:0px;
        height:auto;
    }
`;


export const TextLayout = styled.div`
    padding-left:10px;

    @media ${device.laptop} {
        padding-left:0px;
    }
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
    margin-bottom:20px;
`;


export const FeaturesLayout = styled.div`
    display:flex;
    align-items:center;
    // margin-top:10px;
    
    img{
        width:50px;
        height:auto;
        height:60px;

        @media ${device.mobileM} {
            width:40px;
            height:40px;
        }
        @media ${device.mobileXL} {
            width:30px;
            height:30px;
        }
        @media ${device.mobileM} {
            width:20px;
            height:20px;
        }
    }
`;

export const FeaturesTextLayout = styled.div`
    margin-left:15px;

    h5{
        line-height:1;
        text-align:left;
        margin-bottom:0px;
    }

    @media ${device.mobileXL} {
        margin-left:10px;
        h5{
            font-size:17px;
            margin-left:0px;
        }
    }
    @media ${device.mobileM} {
        margin-left:10px;
        h5{
            font-size:14px;
            margin-left:0px;
        }
    }



`;




export const QuoteBtn = styled(Commonbtn)`
    margin-top: 30px;
`;
