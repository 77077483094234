import React from 'react';
import {OurServicesSection,BackgroundImage,SectionWrapper,HeadingLayout,Heading,OurServicesRow,
    OurServicesCardWrapper,OurServicesCardImgHolder,OurServicesCard
} from './ourservices.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';

const OurServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            homePage1Json {
                OurServices {
                    Heading
                    OurServicesRight{
                        OurServicesCardIcon
                        OurServicesCardHeading
                        OurServicesCardPara
                    }
                }
            }
        }
    `);
    const OurServicesData = JSONData.homePage1Json.OurServices;
    return(
        <OurServicesSection id="OurServicesSection">

            <BackgroundImage src="../home-1-images/small-banner.jpg" alt=""/>
            <HeadingLayout>
                <Heading>
                {OurServicesData.Heading}
                </Heading>
            </HeadingLayout>

            <SectionWrapper>
                <Container>
                    <OurServicesRow>
                    {
                        OurServicesData.OurServicesRight.map((item,idx) => {
                        return <Col lg="4" md="6" sm="12">
                                    <OurServicesCardWrapper position={idx+1}>
                                        <OurServicesCard>
                                            <OurServicesCardImgHolder>
                                                <img src={item.OurServicesCardIcon} alt=""/>
                                            </OurServicesCardImgHolder>
                                            <h5>{item.OurServicesCardHeading}</h5>
                                            <p>{item.OurServicesCardPara}</p>
                                        </OurServicesCard>
                                    </OurServicesCardWrapper>
                            </Col>
                        })
                    }
                    </OurServicesRow>
                </Container>
            </SectionWrapper>
        </OurServicesSection>
    )
}
export default OurServices;
